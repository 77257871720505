<template>
    <div class="layout">
        <Header :menus="menus"></Header>
        <div class="page-section">
            <div class="banner document-banner" :class="$style.banner">
                <div class="banner-search">
                    <div class="banner-search-title">
                        文档中心
                    </div>
                    <input
                        class="banner-search-input"
                        placeholder="请输入固件版本号"
                    />
                </div>
            </div>
            <div class="container container-evelate">
                <Elevate :list="listData"> </Elevate>
            </div>
            <div class="container container-advantage">
                <div class="container-wrap">
                    <div class="c-title">
                        WIF-OS优势
                    </div>
                    <div class="split"></div>
                    <div class="c-subTitle">
                        网飞物联基于业务需求优化安卓系统，搭配天工管理系统<br />
                        为用户提供全链条的管理服务
                    </div>
                    <div class="advantage-wrap">
                        <ul class="advantage-content">
                            <li class="advantage-item">
                                <div>
                                    <img
                                        src="../assets/doc/dev-a-1.png"
                                        alt=""
                                    />
                                </div>
                                <div class="advantage-item-title">
                                    稳定的SDK
                                </div>
                                <div class="advantage-item-subTitle">
                                    可以同时支撑数十万台<br />
                                    设备同步运行
                                </div>
                            </li>
                            <li class="advantage-item">
                                <div>
                                    <img
                                        src="../assets/doc/dev-a-2.png"
                                        alt=""
                                    />
                                </div>
                                <div class="advantage-item-title">
                                    低功耗，高扩展性
                                </div>
                                <div class="advantage-item-subTitle">
                                    采用低功耗的主控IC<br />
                                    增加更多的业务接口
                                </div>
                            </li>
                            <li class="advantage-item">
                                <div>
                                    <img
                                        src="../assets/doc/dev-a-3.png"
                                        alt=""
                                    />
                                </div>
                                <div class="advantage-item-title">
                                    软件级网络路由
                                </div>
                                <div class="advantage-item-subTitle">
                                    硬件设备通过管理系统<br />
                                    更便捷的接入
                                </div>
                            </li>
                            <li class="advantage-item">
                                <div>
                                    <img
                                        src="../assets/doc/dev-a-4.png"
                                        alt=""
                                    />
                                </div>
                                <div class="advantage-item-title">
                                    系统层深度优化
                                </div>
                                <div class="advantage-item-subTitle">
                                    基于业务需求深度优化 <br />
                                    安卓系统提供更贴合<br />
                                    业务的操作系统
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="container container-community">
                <div class="container-wrap">
                    <div class="c-title">
                        开发者中心
                    </div>
                    <div class="community-wrap">
                        <ul class="community-content">
                            <li class="community-item">
                                <div class="community-item-left">
                                    <div class="community-item-left-title">
                                        热门技术问答
                                    </div>
                                    <div class="community-item-left-text">
                                        <p>1、R01主板串口驱动兼容那些外设？</p>
                                        <p>2、21.5寸屏用哪个固件比较好？</p>
                                        <p>
                                            3、A33的主板与RK3288的主板，用哪个比较好？
                                        </p>
                                    </div>
                                </div>
                                <div class="community-item-right">
                                    查看更多
                                </div>
                            </li>
                            <li class="community-item">
                                <div class="community-item-left">
                                    <div class="community-item-left-title">
                                        活动预告
                                    </div>
                                    <div class="community-item-left-text">
                                        <p>
                                            1、天工管理平台业务场景适用案例
                                            <a style="padding-left:55px;"
                                                >线上LIVE</a
                                            >
                                        </p>
                                        <p>
                                            2、网飞Meet UP —深圳站
                                            <a style="padding-left:121px"
                                                >线下沙龙</a
                                            >
                                        </p>
                                    </div>
                                </div>
                                <div class="community-item-right">
                                    查看更多
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container container-question">
                <div class="container-wrap">
                    <div class="c-title" style="font-size: 20px;">
                        常见问题
                    </div>
                    <div class="split"></div>
                    <ul class="question-content">
                        <li class="question-item">
                            <div class="question-item-title">
                                <div>
                                    兼容问题
                                </div>
                                <div class="question-item-more">
                                    查看更多
                                </div>
                            </div>
                            <div class="question-item-text">
                                主板型号与各个尺寸兼容对照表
                            </div>
                        </li>
                        <li class="question-item">
                            <div class="question-item-title">
                                <div>
                                    固件匹配
                                </div>
                                <div class="question-item-more">
                                    查看更多
                                </div>
                            </div>
                            <div class="question-item-text">
                                产品型号与固件版本对照表
                            </div>
                        </li>
                        <li class="question-item">
                            <div class="question-item-title">
                                <div>
                                    售后政策
                                </div>
                                <div class="question-item-more">
                                    查看更多
                                </div>
                            </div>
                            <div class="question-item-text">
                                网飞产品售后政策
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="container container-experience">
                <div class="container-wrap">
                    <div class="experience-title">
                        免费试用名额等你来拿，赶紧注册吧！
                    </div>
                    <ul class="experience-list">
                        <li>
                            <div class="experience-dot"></div>
                            行业主板
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            安卓智慧屏
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            WIF-OS
                        </li>
                    </ul>
                    <div class="experience-btn">
                        立即体验
                    </div>
                </div>
            </div>
        </div>

        <div class="container container-footer">
            <div class="footer-wrap">
                <span> 2021 Agora, Inc. All rights reserved. </span>
                <span>wif.ink</span>
                <img src="../assets/icon/about.png" alt="" />
                <span>关于我们</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Header, Elevate } from "@components";

export default {
    name: "App",
    components: {
        Header,
        Elevate
    },
    data() {
        return {
            menus: [
                {
                    text: "Wif.ink",
                    id: "app"
                },
                {
                    text: "开发者社区",
                    id: "develop"
                }
            ],
            listData: [
                {
                    title: "开发文档",
                    subTitle: "查看开发文档详情",
                    imgSrc: require("../assets/doc/develop.png")
                },
                {
                    title: "FAQ",
                    subTitle: "常见问题回答",
                    imgSrc: require("../assets/doc/question.png")
                }
            ],
            downloadList: [
                {
                    title: "产品文档",
                    subTitle: "查看在线文档",
                    imgSrc: require("../assets/doc/document.png")
                },
                {
                    title: "SDK 下载",
                    subTitle: "物联网管理SDK下载",
                    imgSrc: require("../assets/doc/xiazai.png")
                },
                {
                    title: "WIF-OS下载",
                    subTitle: "网飞安卓系统下载",
                    imgSrc: require("../assets/doc/download.png")
                },
                {
                    title: "社区中心",
                    subTitle: "前往开发者社区",
                    imgSrc: require("../assets/doc/shequ.png")
                }
            ]
        };
    }
};
</script>
<style lang="less" module>
.banner {
    background: url("~@assets/doc/banner.png") no-repeat;
    height: 520px;
    background-size: cover;
}
</style>
