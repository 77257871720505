<template>
    <div class="layout">
        <Header :menus="menus"></Header>
        <div class="page-section">
            <div class="banner document-banner" :class="$style.banner">
                <div class="banner-search">
                    <div class="banner-search-title">
                        文档中心
                    </div>
                    <input class="banner-search-input" placeholder="搜索" />
                </div>
            </div>
            <div class="container container-evelate">
                <Elevate :list="listData"> </Elevate>
            </div>
            <div class="container container-pc">
                <div class="container-wrap">
                    <div class="c-title">
                        产品
                    </div>
                    <div class="split"></div>
                    <div class="pc-wrap">
                        <div class="pc-title">
                            行业主板
                        </div>
                        <ul class="pc-content">
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="pc-wrap">
                        <div class="pc-title">
                            安卓一体机
                        </div>
                        <ul class="pc-content">
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="pc-wrap">
                        <div class="pc-title">
                            WIF-OS
                        </div>
                        <ul class="pc-content">
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                            <li class="pc-item">
                                <div class="pc-item-left">
                                    <img src="../assets/product.png" alt="" />
                                </div>
                                <div class="pc-item-right">
                                    <div class="pc-item-title">
                                        A33-01型
                                    </div>
                                    <div class="pc-item-subTitle">
                                        1G基础型控制主板
                                    </div>
                                    <div class="pc-item-more">
                                        查看详情
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="container container-experience">
                <div class="container-wrap">
                    <div class="experience-title">
                        免费试用名额等你来拿，赶紧注册吧！
                    </div>
                    <ul class="experience-list">
                        <li>
                            <div class="experience-dot"></div>
                            行业主板
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            安卓智慧屏
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            WIF-OS
                        </li>
                    </ul>
                    <div class="experience-btn">
                        立即体验
                    </div>
                </div>
            </div>

            <div class="container container-download">
                <div class="container-wrap">
                    <Elevate :list="downloadList"> </Elevate>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { Header, Elevate, Footer } from "@components";

export default {
    name: "App",
    components: {
        Header,
        Elevate,
        Footer
    },
    data() {
        return {
            menus: [
                {
                    text: "首页",
                    id: "app"
                },
                {
                    text: "社区",
                    id: "about"
                }
            ],
            listData: [
                {
                    title: "API参考",
                    subTitle: "查看API详情",
                    imgSrc: require("../assets/doc/api.png")
                },
                {
                    title: "SDK下载",
                    subTitle: "下载WIF-OS的SDK",
                    imgSrc: require("../assets/doc/sdk.png")
                },
                {
                    title: "FAQ",
                    subTitle: "常见问题回答",
                    imgSrc: require("../assets/doc/question.png")
                }
            ],
            downloadList: [
                {
                    title: "产品文档",
                    subTitle: "查看在线文档",
                    imgSrc: require("../assets/doc/document.png")
                },
                {
                    title: "SDK 下载",
                    subTitle: "物联网管理SDK下载",
                    imgSrc: require("../assets/doc/xiazai.png")
                },
                {
                    title: "WIF-OS下载",
                    subTitle: "网飞安卓系统下载",
                    imgSrc: require("../assets/doc/download.png")
                },
                {
                    title: "社区中心",
                    subTitle: "前往开发者社区",
                    imgSrc: require("../assets/doc/shequ.png")
                }
            ]
        };
    }
};
</script>
<style lang="less" module>
.banner {
    background: url("~@assets/doc/banner.png") no-repeat;
    height: 520px;
    background-size: cover;
}

@media screen and (max-width: 1200px) {
    .banner {
        height: 320px;
    }
}
</style>
