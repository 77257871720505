<template>
    <div style="height: 100%;">
        <Header :menus="menus"></Header>
        <div class="page-section">
            <RightLian />
            <div class="select1">
                <el-select
                    placeholder="网飞系统服务接口文档"
                    style="width:80%;margin-top:20px;font-size:22rem;margin-left:0px "
                >
                    <el-option hidden> </el-option>
                    <el-tree
                        style="width:80%;margin-left:200px"
                        v-if="!isShowResult"
                        ref="tree"
                        node-key="id"
                        :default-expanded-keys="defaultExpand"
                        :data="data"
                        lazy
                        :default-expand-all="true"
                        :load="loadNode"
                        :props="props"
                        @node-click="showDocumnet"
                    ></el-tree>
                    <el-tree
                        :default-expand-all="true"
                        v-if="isShowResult"
                        ref="tree"
                        node-key="id"
                        :data="results"
                        @node-click="showDocumnet"
                    ></el-tree>
                </el-select>
            </div>
            <div class="doc-container">
                <div class="left-contents">
                    <!-- <el-input
                      placeholder="输入关键字进行过滤"
                      prefix-icon="el-icon-search"
                      v-model="filterText">
                    </el-input> -->
                    <el-tree
                        v-if="!isShowResult"
                        ref="tree"
                        node-key="id"
                        :default-expanded-keys="defaultExpand"
                        :data="data"
                        lazy
                        :load="loadNode"
                        :props="props"
                        @node-click="showDocumnet"
                    ></el-tree>
                    <el-tree
                        v-if="isShowResult"
                        ref="tree"
                        node-key="id"
                        :data="results"
                        @node-click="showDocumnet"
                    ></el-tree>
                </div>
                <!-- <div v-if="isShowResult" class="result">
                    <div @click="toNode(item.id)" class="item-container" v-for="item in results" :key='item.id'>
                        <div class="item-title">{{item.type_name}}</div>
                        <div class="item-content">
                            <div>{{item.type_name}}</div>
                            <div v-html="item.text"></div>
                        </div>
                    </div>
                 </div> -->
                <div class="doc-content md">
                    <h1>{{ chapter }}</h1>
                    <div v-html="htmldoc"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Footer, Header } from "@components";
import RightLian from "../components/lianXi/rightLian";

import { marked } from "marked";
import "@style/md.css";
import hljs from "highlight.js"; // 引入 highlight.js
// import "@style/styles/monokai-sublime.css"; // 引入高亮样式 这里我用的是sublime样式
import "highlight.js/styles/atom-one-light.css"; // 引入高亮样式 这里我用的是sublime样式
export default {
    components: {
        Header,
        Footer,
        RightLian
    },
    watch: {
        filterText(val) {
            if (val.length != 0) {
                this.isShowResult = true;
                this.search();
            } else {
                this.isShowResult = false;
            }
        }
    },
    data() {
        return {
            menus: [
                {
                    text: "首页",
                    id: "app"
                },
                {
                    text: "产品中心",
                    id: "products"
                },

                {
                    text: "关于我们",
                    id: "about"
                },
                {
                    text: "管理平台",
                    id: "guanlipt"
                },
                {
                    text: "开发者文档",
                    id: "devdoc"
                }
            ],
            data: [],
            props: {
                label: "type_name",
                // children: 'status',
                isLeaf: (data, node) => {
                    if (data.status == 1) {
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            pid: "",
            id: "",
            htmldoc: "",
            chapter: "",
            filterText: "",
            defaultExpand: [],
            initLoad: true,
            isShowResult: false,
            results: [],
            textType: 1
        };
    },
    mounted() {
        this.getDocumnetList();
        // const link = document.createElement('link')
        // link.type = 'text/css'
        // link.rel = 'stylesheet'
        // link.href = 'https://cdn.bootcss.com/github-markdown-css/2.10.0/github-markdown.min.css'
        // document.head.appendChild(link)
    },
    methods: {
        //节点筛选
        search() {
            this.axios({
                url: "/index/Document/search",
                method: "post",
                data: {
                    text: this.filterText
                }
            }).then(res => {
                // console.log(res);
                if (res.data.status == 1) {
                    this.results = res.data.data;
                    this.results = this.fliterCss(this.results);
                    // if(this.results.length==0){
                    //     this.isShowResult = false;
                    // }
                } else {
                    this.$message.warning(res.data.msg);
                }
            });
        },
        // CSS过滤
        fliterCss(data) {
            let content = [];
            data.forEach(item => {
                // let strSum = item.text.match(this.filterText);
                // // console.log(strSum);
                // var re =new RegExp(,"gim"); // re为/^\d+bl$/gim
                // let chr = item.text.match("style=\".*\">");
                let chr = item.text
                    .replace(/<[^<>]+>/g, "")
                    .replace(/&nbsp;/gi, "");
                let chrSum = chr.match(this.filterText);
                if (chrSum) {
                    item.text = chrSum.input;
                    item.text = this.heightLight(item.text, this.filterText);
                    content.push(item);
                }
            });
            return content;
        },
        //高亮关键字
        heightLight(str, key) {
            let reg = new RegExp("(" + key + ")", "g");
            let newstr = str.replace(
                reg,
                "<font style='background:#ff0;'>$1</font>"
            );
            return newstr;
        },
        //跳转到指定文档
        toNode(id) {},
        //懒加载
        loadNode(node, resolve) {
            // console.log(node, "aaa");
            if (node.level === 0) {
                return resolve([{ name: "region" }]);
            }
            if (node.data.status == 1) {
                //有子目录
                this.pid = node.data.id;
                this.getChildList(resolve);
            }
        },
        //获取全部目录
        getDocumnetList() {
            this.axios({
                url: "/index/Document/se_title",
                method: "post",
                data: {
                    text: ""
                }
            }).then(res => {
                if (res.data.status == 1) {
                    // console.log(res);
                    this.data = res.data.data.data;
                    for (let i in this.data) {
                        let item = this.data[i];
                        if (
                            item.status == 1 &&
                            this.defaultExpand.length == 0
                        ) {
                            this.defaultExpand.push(item.id);
                            break;
                        }
                    }
                } else {
                    this.$message.warning(res.data.msg);
                }
            });
        },
        //获取子目录
        getChildList(resolve) {
            this.axios({
                url: "/index/Document/se_title",
                method: "post",
                data: {
                    pid: this.pid,
                    text: ""
                }
            }).then(res => {
                if (res.data.status == 1) {
                    // console.log(res);
                    resolve(res.data.data.data);
                    if (this.initLoad) {
                        for (let i in res.data.data.data) {
                            let item = res.data.data.data[i];
                            if (item.status == 1) {
                                this.defaultExpand.push(item.id);
                                break;
                            } else if (item.status == 2) {
                                // console.log("点击加载");
                                this.$nextTick().then(() => {
                                    let treeNode = this.$refs.tree.getNode(
                                        item.id
                                    );
                                    this.showDocumnet(treeNode.data, treeNode);
                                    this.$refs.tree.setCurrentKey(null);
                                    this.$refs.tree.setCurrentKey(item.id);
                                    this.initLoad = false;
                                });
                                break;
                            }
                        }
                    }
                } else {
                    this.$message.warning(res.data.msg);
                }
            });
        },
        //查看文档内容
        showDocumnet(data, node, e) {
            this.$refs.tree.setCurrentKey(null);
            // console.log(data, node, e);
            if (data.status == 2 || !data.status) {
                // console.log("存在内容");
                this.axios({
                    url: "/index/Document/se_document",
                    method: "post",
                    data: {
                        id: data.id
                    }
                }).then(res => {
                    // console.log(res);
                    if (res.data.status == 1) {
                        // console.log(res);
                        if (res.data.data.type == "1") {
                            this.textType = 1;
                            this.htmldoc = res.data.data.text;
                            this.htmldoc = this.htmldoc.replace(
                                'href="',
                                'href="' + window.url
                            );
                            this.chapter = data.type_name;
                        } else {
                            this.textType = 2;
                            this.htmldoc = res.data.data.md;
                            // var rendererMD = new marked.Renderer();
                            // marked.setOptions({
                            //   renderer: rendererMD,
                            //   highlight: ()=> {
                            //     return hljs.highlightAuto(this.htmldoc).value;
                            //   },
                            //   pedantic: false,
                            //   gfm: true,
                            //   tables: true,
                            //   breaks: false,
                            //   sanitize: false,
                            //   smartLists: true,
                            //   smartypants: false,
                            //   xhtml: false,
                            //   markdown:true,
                            // });
                            // this.htmldoc = marked.parse(this.htmldoc);// 将markdown内容解析
                            this.chapter = data.type_name;
                        }
                    } else {
                        this.$message.warning(res.data.msg);
                    }
                });
            }
        }
    }
};
</script>
<style scoped="scoped">
.page-section {
    height: 100%;
    width: 1350px;
    margin: auto;
}
.doc-container {
    height: calc(100% - 30px);
    background: #fff;
    border-radius: 5px;
    margin-top: 30px;
    position: relative;
    border: 1px solid rgb(235, 236, 237);
}
.left-contents {
    /* height:calc(100% - 88px); */
    height: 100%;
    overflow-y: scroll;
    width: 260px;
    float: left;
    padding: 10px 0;
    /* border-right: 1px solid rgb(235,236,237); */
}
.left-contents /deep/ .el-input {
    width: 90%;
    margin: 5%;
}
.doc-content {
    width: calc(100% - 260px);
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
    float: right;
    border-left: 1px solid rgb(235, 236, 237);
}
.el-tree {
    color: #000000;
    font-size: 16px;
}
.el-tree /deep/ .is-current {
    /* color: #0066ff; */
    background-color: rgba(0, 102, 255, 0.1);
}
.el-tree /deep/ .el-tree-node:focus > .el-tree-node__content {
    /* color: #0066ff; */
    background-color: rgba(0, 102, 255, 0.1);
}
.el-tree /deep/ .el-tree-node__content:hover {
    background-color: rgba(0, 102, 255, 0.1);
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: none;
    /* background-color: #F5F5F5; */
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #ffffff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    /* background-color: #c8c8c8; */
    background-color: rgba(0, 102, 255, 0.4);
}
.result {
    width: auto;
    height: 600px;
    border: 1px solid rgb(207, 212, 219);
    border-radius: 5px;
    position: absolute;
    top: 60px;
    left: 20px;
    z-index: 100;
    background: #fff;
    padding: 10px;
    overflow-y: scroll;
}
.item-container {
    width: 500px;
}
.item-title {
    font-size: 20px;
    font-weight: 600;
    background-color: rgb(228, 233, 238);
    border-radius: 2px;
    line-height: 40px;
    padding: 0 5px;
}
.item-content {
    display: flex;
    border-bottom: 1px solid rgb(237, 239, 242);
}
.item-content > div:first-child {
    background-color: rgb(248, 250, 252);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px;
    width: 150px;
    border-right: 1px solid rgb(237, 239, 242);
    width: 150px;
}
.item-content > div:last-child {
    padding: 10px;
    width: 350px;
}
</style>

<style scoped>
@media screen and (min-width: 1100px) {
    .select1 {
        display: none;
    }
}
@media screen and (max-width: 1100px) {
    ::v-deep .el-scrollbar__wrap {
        margin-left: 150px;
    }
    ::v-deep .el-tree-node__label {
        font-size: 29px;
        line-height: 100px;
    }
    ::v-deep .el-tree-node__content {
        height: 40px;
    }
    .left-contents {
        display: none;
    }
    .doc-content {
        width: 100%;
    }
    /deep/ .el-input {
        height: 40px;
        font-size: 26px;
    }
}
</style>
