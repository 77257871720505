import Footer from './footer'
import DocFooter from './docFooter'
import Menu from './Menu'
import Header from './header'
import Elevate from './elevate'

export default {

}
export {
    Menu,
    Header,
    Footer,
    Elevate,
    DocFooter,
}
