<template>
    <div style="float: left; width: 22%; margin-left: 15px">
        <div class="zhanKaiTop">
            <img
                src="../../assets/chanpfl.png"
                style="width:10%; margin-left:20px"
            />
            <span style="margin-left:20px">产品分类</span>
        </div>
        <div style="height:220px">
            <el-collapse accordion v-model="a1" class="a1">
                <div
                    v-if="fenLeiList.length === 0"
                    style="width:130px;height:100px;
                margin:0 auto;margin-top:20px"
                >
                    暂未上传产品分类
                </div>
                <div v-for="(item, key) in fenLeiList" :key="key">
                    <el-collapse-item style="margin-left: 10px" :name="key">
                        <template slot="title">
                            <el-button type="text" @click="a(item)">{{
                                item.name
                            }}</el-button>
                        </template>

                        <div
                            v-if="
                                item.product_list === '' ||
                                    item.product_list === undefined ||
                                    item.product_list === null ||
                                    item.product_list.length === 0
                            "
                        >
                            <p style="font-size:14px">暂未添加产品</p>
                        </div>
                        <div
                            v-for="(item1, key1) in item.product_list"
                            @click="xuanze(item1, item)"
                            :key="key1"
                            v-else
                        >
                            <el-button type="text" class="text1">{{
                                item1.name
                            }}</el-button>
                        </div>
                    </el-collapse-item>
                </div>
            </el-collapse>
        </div>

        <div class="zhanKaiTop" style="margin-top: 120px">
            <img
                src="../../assets/wxin.png"
                style="width:10%; margin-left:20px"
            />
            <span style="margin-left:20px">联系方式</span>
        </div>
        <div class="biankuang">
            <p>联系人：杨经理</p>
            <p>手机号：178452*******</p>
            <p>邮箱：175****@wif.ink</p>
            <p>微信：yang</p>
            <div>
                <img src="../../assets/tu.jpg" />
            </div>
        </div>
        <div
            style="margin-top: 20px"
            v-for="(item, key2) in chanPingList2"
            :key="key2"
            v-if="item.type === 2"
        >
            <el-card class="box-card" style="height: 300px">
                <p style="text-align: center; margin-bottom: 20px">
                    {{ item.name }}
                </p>

                <div style="width: 32%; float: left; height: 100%">
                    <img
                        :src="item.thumb_img"
                        style="margin-top:50px;width: 100%; height: 100%"
                    />
                </div>

                <div
                    style="
            float: right;
            display: inline-block;
            height: 178px;
            margin-left:-250px;
            width: 110px;
            margin-left:-120px;
          "
                >
                    <div v-for="item1 in item.config" style="margin-left:-50px">
                        <el-button type="primary" class="btn12">
                            {{ item1 }}</el-button
                        ><br />
                    </div>
                    <el-button type="text" class="Pxiangq" @click="chakan(item)"
                        >查看详情 > ></el-button
                    >
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
export default {
    name: "LeftLian",

    data() {
        return {
            a1: [],
            List: [1, 2],
            fenLeiList: [],
            chanPingList2: []
        };
    },
    mounted() {
        this.getGroup();
        this.getHot();
    },
    methods: {
        fu(id) {},
        a(item) {
            console.log(item);
            console.log(this.a1);
            this.$emit("onChange", item.id);
        },
        getHot() {
            this.axios({
                url: "/index/product/getHot",
                method: "post",
                data: {}
            }).then(res => {
                this.chanPingList2 = res.data.data;
                console.log(res);
            });
        },
        chakan(scope) {
            console.log(scope);
            let param = {
                id1: scope.id,
                id: scope.product_id
            };

            this.$router.push({
                path: "/proDetail",
                query: { appInfo: JSON.stringify(param) }
            });
        },
        xuanze(item, item1) {
            let param = {
                id1: item1.id,
                id: item.product_id,
                name: item1.name
            };
            this.$router.push({
                path: "/proDetail",

                query: { appInfo: JSON.stringify(param) }
            });
        },
        getGroup() {
            this.axios({
                url: "/index/product/getGroup",
                method: "post",
                data: {}
            }).then(res => {
                this.fenLeiList = res.data.data;
                console.log(res);
            });
        }
    }
};
</script>

<style scoped>
.btn12 {
    min-width: 200px;
    font-size: 2px;
    transform: scale(0.75);
    font-size: 14px;
    margin-top: 10px;
}
/deep/ .el-collapse-item__arrow {
    margin: 0 28px 0 auto;
}
.text1 {
    text-decoration: underline;
    color: rgb(255, 141, 26);
}
.Pxiangq {
    color: red;
    margin-left: 10px;
}
/deep/ .el-submenu__icon-arrow {
    color: rgb(42, 130, 228);
}
/deep/ .el-icon-arrow-down {
    color: rgb(42, 130, 228);
}

/deep/ .el-collapse-item {
    border-bottom: 1px solid #e5e5e5;
}

/deep/ .el-collapse {
    width: 265px;

    height: 290px;
    border: 1px solid rgb(42, 130, 228);

    overflow-x: hidden;
}

/deep/ .el-collapse-item__content {
}

.neiRong {
    width: 60%;
    height: 200px;
}
.zhanKaiTop {
    color: #fff;
    line-height: 56px;
    height: 56px;
    width: 265px;
    background-color: rgb(42, 130, 228);
}
.xianshi {
    border: 1px solid rgb(42, 130, 228);
    width: 265px;
}

.biankuang {
    text-align: left;
    font-size: 14px;
    border: 1px solid rgb(42, 130, 228);
    width: 265px;
}
.biankuang > p {
    color: rgb(255, 141, 26);
    margin-top: 20px;
    margin-left: 10px;
}
</style>
