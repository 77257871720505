<template>
    <div>
    </div>
</template>
<script>
export default {
    name: 'Menu',

}
</script>
