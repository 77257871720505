<template>
    <div class="header-section" :class="{ 'box-shadow': headerScroll }">
        <div class="header f f-ai-c f-jc-sb">
            <div class="header-left">
                <img src="../../assets/logo.png" class="logo" />
                <div class="header-left-title">
                    {{ title }}
                </div>
                <div
                    v-if="subTitle"
                    class="verticle-split"
                    style="margin-left:20px"
                ></div>
                <div class="header-left-subTitle" style="margin-left:20px">
                    {{ subTitle }}
                </div>
            </div>
            <ul class="menu f f-ai-c f-jc-fe">
                <li v-for="menu in menus" :key="menu.id">
                    <span v-if="menu.id != 'guanlipt'">
                        <router-link :to="menu.id">
                            {{ menu.text }}
                        </router-link>
                    </span>

                    <span v-else>
                        <a
                            href="https://tiangong-dev.wif.ink/#/login"
                            target="_blank"
                        >
                            {{ menu.text }}</a
                        >
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "Header",
    props: {
        menus: {
            type: Array,
            default: () => [
                {
                    text: "首页",
                    id: "app"
                },
                {
                    text: "关于我们",
                    id: "about"
                },
                {
                    text: "开发者文档",
                    id: "devdoc"
                }
            ]
        },
        title: String,
        subTitle: String
    },
    data() {
        return {
            headerScroll: false
        };
    },
    mounted() {
        document.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        document.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll() {
            this.headerScroll = window.scrollY > 0;
        }
    }
};
</script>
