<template>
    <div v-if="bannerList.length != 0">
        <Header :menus="menus"></Header>

        <div class="page-section">
            <!-- <div class="banner"
                 :class="$style.banner">
            </div> -->
            <RightLian />

            <!-- <el-carousel class="banner" indicator-position="inside">
        <el-carousel-item>
          <img class="img2" src="../assets/banner.png" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="img2" src="../assets/1.png" />
          <div class="d1">
            <h3 class="h1">网飞物联</h3>
            <p class="p1">
              网飞物联科技有限公司，简称网飞物联。公司专注于物联网触控显示设备、物联网操作系统及设备管控服务平台等产品研发，是集研发、生产、营销、售后服务于一体的技术导向型企业。<br />
              <br />
              设备管控服务平台采用了云原生架构，以设备管理、应用管理、运维监控及大数据分析为平台核心，提供友好的操作页面。为企业解决物联网专用设备在运行期间的远程运维和监控工作，让用户足不出户轻松管理海量设备并监控设备的运行稳定性、连续性、健康度等。大大提高了设备管理运维效率和成本管理的水平。<br />
              <br />
              公司产品在智能城市、智能物流等多个项目中，为客户的产品和服务赋能。同时，公司拥有专业团队积极推进物联网产品及平台在其他应用场景的应用，积极帮助企业进行“互联网+”的转型，促进产业升级。
            </p>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img class="img2" src="../assets/2.png" />
          <div class="d2">
            <h3 class="h2">专为复杂环境打造的多用途安卓一体机</h3>
            <p class="p2">
              全新设计的WIF-T100系列安卓一体机不仅有可靠稳固的品质保证，通过了各种工规认证，采用防水防震防尘设计，在复杂环境下依然能够稳定流畅运行。
              <br />
              <br />
              设备主板自助设计，并配置了HDMI/USB/LVDS/SIM/485等丰富接口，加装WIFI天线增强信号，支持嵌入式、壁挂式、悬臂式等多种安装方式。除此，支持多种规格的红外、光学、电容、电阻多点触摸方案，使设备在各种场景下均能做到操作灵活简便，满足你的多方位的需求。
              <br />
              <br />
              设备操作系统集成了多种成熟三方SDK，提高用户业务应用的开发效率。
            </p>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img class="img2" src="../assets/3.png" />
          <div class="d3">
            <h3 class="h3">网飞物联网管理平台</h3>
            <p class="p3">
              <span>
                <img src="../assets/icon/icon-check.png" alt="" />
                业务闭环管理
              </span>
              <span>
                <img src="../assets/icon/icon-check.png" alt="" />
                云原生架构（支持私有云本地部署）
              </span>
              <span>
                <img src="../assets/icon/icon-check.png" alt="" />
                热备高可用部署
              </span>
              <span>
                <img src="../assets/icon/icon-check.png" alt="" />
                弹性伸缩
              </span>
              <span>
                <img src="../assets/icon/icon-check.png" alt="" />
                数据全链路加密
              </span>
              <span>
                <img src="../assets/icon/icon-check.png" alt="" />
                丰富的开发接口
              </span>
            </p>
          </div>
        </el-carousel-item>
      </el-carousel>-->

            <el-carousel
                :height="bannerHeight"
                class="banner"
                indicator-position="inside"
                v-if="bannerList.length != 0"
            >
                <el-carousel-item
                    v-for="(item, index) in bannerList"
                    :key="index"
                >
                    <img class="img2" :src="item" :height="bannerHeight" />
                </el-carousel-item>
            </el-carousel>
            <div class="container container-productCenter">
                <div class="container-wrap">
                    <div v-if="stat1">
                        <el-card class="box-card" style="height: 500px">
                            <div class="tuOneOne" style="margin-left: 20px">
                                <div
                                    v-for="(item, index) in ggw1.imgs"
                                    style="
                    margin-top: 25px;
                    width: 260px;
                    height: 140px;
                    border-bottom: 2px dashed #e5e5e5;
                  "
                                    :key="index"
                                >
                                    <img
                                        :src="item"
                                        style="width: 260px; height: 100px"
                                    />
                                </div>
                            </div>
                            <div class="TuOneDiv">
                                <!--  <img
                                    class="imgType"
                                    v-if="ggw1.ad_type === 1"
                                    src="../assets/xinpin.png"
                                />

                                <img
                                    v-else
                                    src="../assets/baokuan.png"
                                    class="imgType"
                                />-->
                                <img
                                    :src="ggw1.product_img"
                                    style="width: 500px; margin-bottom: 40px; float: left"
                                />
                            </div>

                            <div
                                style="
                  float: right;
                  margin-right: 50px;
                  height: 430px;
                  display: inline-block;
                  text-align: right;
                  margin-top: 50px;
                "
                            >
                                <div v-for="item in ggw1.product_labels">
                                    <p
                                        style="
                      font-style: italic;
                      font-size: 28px;
                      color: #0c5ba9;
                      margin-top: 10px;
                    "
                                    >
                                        {{ item }}
                                    </p>
                                </div>
                                <p class="pl3">
                                    {{ ggw1.ad_name }}
                                </p>

                                <div class="ckxq"></div>
                                <!--     <img
                                    src="../assets/ckxq.png"
                                    style="width:250px"
                                />-->

                                <el-button
                                    type="primary"
                                    class="Pxiangq"
                                    style="margin-top: 20px"
                                    @click="tiaozhuan1()"
                                >
                                    查看详情
                                </el-button>

                                <p
                                    style="
                    margin-top: 20px;
                    margin-right: 62px;
                    font-size: 1.2rem;
                    color: #ffffff;
                  "
                                >
                                    型号：{{ ggw1.product_mode }}
                                </p>
                            </div>
                        </el-card>
                    </div>
                    <div style="margin-top: 50px" v-if="stat2">
                        <el-card class="box-card" style="height: 500px">
                            <div class="tuOneOne" style="margin-left: 20px">
                                <div
                                    v-for="(item, index) in ggw2.imgs"
                                    style="
                    margin-top: 25px;
                    width: 260px;
                    height: 140px;
                    border-bottom: 2px dashed #e5e5e5;
                  "
                                    :key="index"
                                >
                                    <img
                                        :src="item"
                                        style="width: 260px; height: 100px"
                                    />
                                </div>
                            </div>
                            <div class="TuOneDiv">
                                <!--<img
                                    v-if="ggw2.ad_type === 1"
                                    src="../assets/xinpin.png"
                                    class="imgType"
                                />

                                <img
                                    v-else
                                    src="../assets/baokuan.png"
                                    class="imgType"
                                />
                                -->
                                <img
                                    :src="ggw2.product_img"
                                    style="width: 500px; margin-bottom: 40px; float: left"
                                />
                            </div>

                            <div
                                style="
                  float: right;
                  margin-right: 50px;
                  height: 430px;
                  display: inline-block;
                  text-align: right;
                  margin-top: 50px;
                "
                            >
                                <div
                                    v-for="(item, index) in ggw2.product_labels"
                                    :key="index"
                                >
                                    <p
                                        style="
                      font-style: italic;
                      font-size: 28px;
                      color: #0c5ba9;
                      margin-top: 10px;
                    "
                                    >
                                        {{ item }}
                                    </p>
                                </div>
                                <p class="pl3">
                                    {{ ggw2.ad_name }}
                                </p>

                                <div class="ckxq"></div>
                                <!--     <img
                                    src="../assets/ckxq.png"
                                    style="width:250px"
                                />-->

                                <el-button
                                    type="primary"
                                    class="Pxiangq"
                                    style="margin-top: 20px"
                                    @click="tiaozhuan2()"
                                >
                                    查看详情
                                </el-button>

                                <p
                                    style="
                    margin-top: 20px;
                    margin-right: 62px;
                    font-size: 1.2rem;
                    color: #ffffff;
                  "
                                >
                                    型号：{{ ggw2.product_mode }}
                                </p>
                            </div>
                        </el-card>
                    </div>
                </div>
            </div>

            <div class="container container-service">
                <div class="container-wrap">
                    <div class="c-title">快捷易用 扩展性强 售后保障</div>
                    <div class="c-subTitle">
                        好用便捷，方便每一位使用系统的用户
                    </div>
                    <div class="service-content">
                        <img class="service-ct" src="../assets/service.png" />
                        <div class="service-item service-tl">
                            <img src="../assets/icon-1.png" />
                            <div class="service-item-right">
                                <div class="service-item-title">傻瓜式对接</div>
                                <div class="service-item-subTitle">
                                    "面对开发者的SDK，方便开发者对接，
                                    专业技术团队辅助对接，随接随用， 方便快捷"
                                </div>
                            </div>
                        </div>
                        <div class="service-item service-bl">
                            <img src="../assets/icon-2.png" />
                            <div class="service-item-right">
                                <div class="service-item-title">可扩展</div>
                                <div class="service-item-subTitle">
                                    基于业务需求，安卓机终端可以扩展多种外设模块，应用范围广
                                </div>
                            </div>
                        </div>
                        <div class="service-item service-tr">
                            <img src="../assets/icon-3.png" />
                            <div class="service-item-right">
                                <div class="service-item-title">系统健全</div>
                                <div class="service-item-subTitle">
                                    面对开发者的S一体终端机+WIFO+WIF物联网
                                    系统，三端结合保证业务稳定运转，物联网管
                                    理系统提升业务运营效率。
                                </div>
                            </div>
                        </div>
                        <div class="service-item service-br">
                            <img src="../assets/icon-4.png" />
                            <div class="service-item-right">
                                <div class="service-item-title">售后保障</div>
                                <div class="service-item-subTitle">
                                    安卓机一年质量保证
                                    <br />
                                    系统终身免费升级
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container container-solution">
                <div class="container-wrap">
                    <div class="c-title">超灵活产品组合，多样化功能套件</div>
                    <div class="c-subTitle">最了解行业场景的解决方案</div>
                    <div class="solution-content">
                        <div class="solution-item">
                            <div class="solution-item-left">
                                <img src="../assets/solution.png" alt="" />
                            </div>
                            <div class="solution-item-right">
                                <div class="solution-item-title">智能柜</div>
                                <div class="solution-item-split"></div>
                                <div class="solution-item-subTitle">
                                    通过实时互动技术，实现快递扫描投件，用户取件的功能，零距离，无接触随时取件
                                </div>
                            </div>
                        </div>

                        <div class="solution-item">
                            <div class="solution-item-left">
                                <img
                                    src="../assets/cdz.png"
                                    style="width: 180px; margin-right: 10px"
                                    alt=""
                                />
                            </div>
                            <div class="solution-item-right">
                                <div class="solution-item-title">充电桩</div>
                                <div class="solution-item-split"></div>
                                <div class="solution-item-subTitle">
                                    方便充电用户了解价格、充电时间，结算等信息，让客户充电更放心
                                </div>
                            </div>
                        </div>
                        <div class="solution-item">
                            <div class="solution-item-left">
                                <img src="../assets/cky.png" alt="" />
                            </div>
                            <div class="solution-item-right">
                                <div class="solution-item-title">出库仪</div>
                                <div class="solution-item-split"></div>
                                <div class="solution-item-subTitle">
                                    快速扫面面单，避免错件、漏件，降低出错率，拍照取证，快速追查，自动出库，节约人工成本
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container container-diy">
                <div class="container-wrap">
                    <div class="c-title">DIY出来只能硬件的艺术</div>
                    <div class="c-subTitle">随心所欲组装您想要的</div>
                </div>
            </div>

            <div class="container contianer-frame">
                <div class="container-wrap">
                    <div class="c-title">完善的物联网架构</div>
                    <div class="c-subTitle">更加“懂”你</div>
                    <div class="frame-content">
                        <div class="frame-left">
                            <div class="frame-left-title">管理平台架构</div>
                            <div class="frame-left-subTitle">
                                依托 SD-RTN™
                                全球实时传输网，保证实时音视频、实时消息、教学白板等内容
                            </div>
                            <div class="frame-left-tip">音视频</div>
                            <div class="frame-left-tip">实时消息</div>
                            <div class="frame-left-tip">教学白板等内容</div>
                        </div>
                        <img src="../assets/frame.png" />
                    </div>
                </div>
            </div>

            <div class="container container-link">
                <div class="container-wrap">
                    <div class="c-title">他们都在使用我们的服务</div>
                    <div class="c-subTitle">一切从客户出发</div>
                    <div class="link-content">
                        <div class="link-item">
                            <img src="../assets/zgyz.png" />
                            <div class="link-item-title">中国邮政</div>
                        </div>
                        <div class="link-item">
                            <img src="../assets/jtsd.png" />
                            <div class="link-item-title">极兔速递</div>
                        </div>
                        <div class="link-item">
                            <img src="../assets/zrdq.png" />
                            <div class="link-item-title">追日电气</div>
                            <div class="link-item-subTitle"></div>
                        </div>
                        <div class="link-item">
                            <img src="../assets/njbm.png" />
                            <div class="link-item-title">南京百米</div>
                        </div>
                        <div class="link-item">
                            <img src="../assets/xywx.png" />
                            <div class="link-item-title">欣意无限</div>
                        </div>

                        <div class="link-item">
                            <img src="../assets/xah.png" />
                            <div class="link-item-title">讯安汇</div>
                        </div>
                        <div class="link-item">
                            <img src="../assets/sdlkj.png" />
                            <div class="link-item-title">世达龙科技</div>
                        </div>
                        <div class="link-item">
                            <img src="../assets/wcx.png" />
                            <div class="link-item-title">微潮汐</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { Footer, Header } from "@components";
import RightLian from "../components/lianXi/rightLian";

export default {
    name: "App",
    components: {
        Header,
        Footer,
        RightLian
    },
    data() {
        return {
            bannerList: [],
            menus: [
                {
                    text: "首页",
                    id: "app"
                },
                {
                    text: "产品中心",
                    id: "products"
                },

                {
                    text: "联系我们",
                    id: "about"
                },
                {
                    text: "管理平台",
                    id: "guanlipt"
                },
                {
                    text: "开发者文档",
                    id: "devdoc"
                }
            ],
            flagZhanShi: true,

            list: [],
            ggw1: {},

            ggw2: {},
            stat2: true,
            stat1: true,
            sta1Img: true,
            sta2Img: true,
            bannerHeight: document.documentElement.clientHeight - 200 + "px"
        };
    },
    mounted() {
        this.getAd();
        this.getList();

        window.onresize = () => {
            return () => {
                this.bannerHeight =
                    document.documentElement.clientHeight + "px";
            };
        };
        // console.log(this.bannerHeight);
    },
    methods: {
        tiaozhuan1() {
            let param = {
                id: this.ggw1.product_id
            };
            this.$router.push({
                path: "/proDetail",
                query: { appInfo: JSON.stringify(param) }
            });
        },
        tiaozhuan2() {
            let param = {
                id: this.ggw2.product_id
            };

            this.$router.push({
                path: "/proDetail",
                query: { appInfo: JSON.stringify(param) }
            });
        },
        hangyezhuti() {
            this.flagZhanShi = true;
        },
        anzhuangyitiji() {
            this.flagZhanShi = false;
        },

        getAd() {
            this.axios({
                url: "/index/product/getAd",
                method: "post",
                data: {}
            }).then(res => {
                // console.log(res.data.data);
                this.list = res.data.data;
                this.ggw1 = res.data.data.ad_1;
                //   console.log(res.data.data.ad_1);

                let a1 = res.data.data.ad_1;
                a1 = JSON.stringify(a1);

                if (a1 === "{}" || a1 === "[]") {
                    this.stat1 = false;
                }
                let a2 = res.data.data.ad_2;
                a2 = JSON.stringify(a2);
                if (a2 === "{}" || a2 === "[]") {
                    this.stat2 = false;
                }
                this.ggw2 = res.data.data.ad_2;
                // console.log(this.ggw2);
            });
        },

        getList() {
            this.axios({
                url: "/index/ad/getList",
                method: "post",
                data: {
                    position_id: "1"
                }
            }).then(res => {
                this.bannerList = res.data.data;
            });
        }
    }
};
</script>

<style scoped>
.Pxiangq {
    font-size: 24px;
    height: 50px;
    border-radius: 25px;
    width: 200px;
    background-color: #56c6f5;
}
.pl3 {
    margin-top: 20px;
    font-size: 44px;
    font-weight: bold;
    color: #0c5ba9;
}
.ckxq {
    width: 200px;
    background: url("~@assets/ckxq.png") no-repeat;
}
.box-card {
    background: url("~@assets/yanse.png") no-repeat;
}
.tuOneOne {
    width: 20%;
    float: left;
}
.TuOneDiv {
    margin-left: 40px;
    width: 35%;
    float: left;
    height: 100%;
}
.PTwoDiv {
    margin-left: 140px;
    height: 430px;
    display: inline-block;
    text-align: left;
}
.TuTwoDiv {
    margin-top: -40px;
    width: 60%;
    margin-right: 20px;
    float: right;
    height: 100%;
}
::v-deep .el-carousel__container {
    width: 100%;
}

/deep/ .el-button--primary {
    border: 0px solid white;
}
.btn1 {
    background-size: 200px;
    font-weight: bolder;
    font-size: 28px;
}
.btn2 {
    background-size: 200px;
    font-weight: bolder;
    font-size: 28px;
}
.btnXQ {
    font-weight: bolder;
    width: 210px;
    font-size: 12px;
    margin-top: 15px;
}
</style>
<style>
/*    .img-descripe{
        position: absolute;
        z-index: 2;
        top: 60px;
        left: 160px;
        color: #ffffff;
        width: 40%;
    }
    .img-descripe h1{
        border-bottom: rgb(221,199,110) 3px solid;
        margin: 30px 0;
        display: inline-block;
        padding-bottom: 5px;
    }
    .img-descripe p{
        padding-bottom: 30px;
        font-size: 18px;
    } */
.banner .d1 {
    position: absolute;
    width: 1200px;
    height: 320px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    padding-top: 50px;
}
.banner .h1 {
    display: inline-block;
    /* margin-top: 10%; */
    font-size: 36px;
    color: #fff;
    line-height: 50px;
    border-bottom: 2px solid #fac50f;
}
.banner .p1 {
    width: 600px;
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    margin-top: 20px;
}
.banner .d2 {
    position: absolute;
    width: 1200px;
    height: 360px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 100px auto;
    z-index: 2;
    padding-top: 20px;
}
.banner .h2 {
    font-size: 36px;
    color: #56fffd;
    line-height: 50px;
    text-align: center;
}
.banner .p2 {
    padding-left: 500px;
    width: 1200px;
    font-size: 14px;
    color: #5fa4ca;
    line-height: 24px;
    margin-top: 60px;
}
.banner .d3 {
    position: absolute;
    width: 1200px;
    height: 400px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    padding-top: 20px;
}

.banner .h3 {
    font-size: 36px;
    color: #e9dc9f;
    line-height: 50px;
    padding-top: 20px;
}
.banner .p3 {
    width: 500px;
    font-size: 26px;
    color: #5fa4ca;
    line-height: 50px;
    margin-top: 20px;
}
.banner .p3 span {
    display: block;
    vertical-align: middle;
}

.banner .p3 img {
    display: inline-block;
    width: 20px;
    height: 18px;
    vertical-align: middle;
}
.imgType {
    width: 50px;
    position: relative;
    left: 20px;
    top: 20px;
}
</style>

<style scoped>
@media screen and (max-width: 1200px) {
    /*   .img2{
        height: 500px;
    }
    ::v-deep .el-carousel__container{
    height: 470px;
    }*/
    .TuOneDiv {
    }
    .TuTwoDiv {
        margin-top: -40px;
        width: 60%;
        margin-right: 40px;
    }
    .PTwoDiv {
    }
}
</style>
