<template>
    <div style="height: 100%" v-if="bannerList.length != 0">
        <Header :menus="menus"></Header>

        <el-carousel class="banner" indicator-position="inside">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <img class="img2" :src="item" />
            </el-carousel-item>
        </el-carousel>
        <div class="page-section1">
            <div>
                <el-carousel height="1020px" indicator-position="outside">
                    <el-carousel-item>
                        <div>
                            <div
                                class="chanPingShiPeiList"
                                v-for="(item, key2) in cpListOne"
                                :key="key2"
                            >
                                <el-card class="box-card" style="height: 980px">
                                    <p class="pName" style="font-size: 36px">
                                        {{ item.name }}
                                    </p>

                                    <el-button
                                        style="font-size: 36px"
                                        class="xuanzhuan"
                                        type="primary"
                                        v-if="item.type === 1"
                                        >新品
                                    </el-button>
                                    <el-button
                                        style="font-size: 36px"
                                        class="xuanzhuan"
                                        type="primary"
                                        v-else
                                        >热销
                                    </el-button>

                                    <div style="width: 100%; height: 300px">
                                        <img
                                            :src="item.thumb_img"
                                            style="width: 100%; height: 300px; margin-top: 20px"
                                        />
                                    </div>
                                    <div style="margin-top: 80px">
                                        <div
                                            v-for="(item1, key1) in item.config"
                                            :key="key1"
                                        >
                                            <el-button
                                                type="primary"
                                                class="btn2"
                                                >{{ item1 }}</el-button
                                            >
                                        </div>
                                        <el-button
                                            type="text"
                                            class="Pxiangq"
                                            style="
                        margin-top: 40px;
                        margin-left: 16%;
                        font-size: 40px;
                      "
                                            @click="chakan(item)"
                                        >
                                            查看详情 > >
                                        </el-button>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div>
                            <div
                                class="chanPingShiPeiList"
                                v-for="(item, key2) in cpListTwo"
                                :key="key2"
                            >
                                <el-card class="box-card" style="height: 980px">
                                    <p class="pName" style="font-size: 36px">
                                        {{ item.name }}
                                    </p>

                                    <el-button
                                        style="font-size: 36px"
                                        class="xuanzhuan"
                                        type="primary"
                                        v-if="item.type === 1"
                                        >新品
                                    </el-button>
                                    <el-button
                                        style="font-size: 36px"
                                        class="xuanzhuan"
                                        type="primary"
                                        v-else
                                        >热销
                                    </el-button>

                                    <div style="width: 100%; height: 300px">
                                        <img
                                            :src="item.thumb_img"
                                            style="width: 100%; height: 300px; margin-top: 20px"
                                        />
                                    </div>
                                    <div style="margin-top: 80px">
                                        <div
                                            v-for="(item1, key1) in item.config"
                                            :key="key1"
                                        >
                                            <el-button
                                                type="primary"
                                                class="btn2"
                                                >{{ item1 }}</el-button
                                            >
                                        </div>
                                        <el-button
                                            type="text"
                                            class="Pxiangq"
                                            style="
                        margin-top: 40px;
                        margin-left: 16%;
                        font-size: 40px;
                      "
                                            @click="chakan(item)"
                                        >
                                            查看详情 > >
                                        </el-button>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div style="margin-top: 80px">
                <div style="margin-left: 10px">
                    <div style="text-aglin: center; display: inline-block">
                        <el-select
                            v-model="listQueryShiPei.name"
                            @change="changeXuze"
                            placeholder="产品分类"
                        >
                            <el-option value="0" label="全部产品"></el-option>
                            <el-option
                                v-for="(item, index) in fenLeiList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div
                        style="float: right; margin-right: 20px; display: inline-block"
                    >
                        <el-input
                            class="shuru"
                            placeholder="请输入关键字搜索"
                            style="width: 400px"
                            v-model="listQueryShiPei.keyword"
                        />
                        <el-button
                            type="primary"
                            style="
                margin-left: 10px;
                height: 80px;
                width: 100px;
                font-size: 24px;
              "
                            @click="getListShiPei"
                            >搜索</el-button
                        >
                    </div>
                </div>

                <div style="margin-top: 40px">
                    <!-- style="display:flex;flex-wrap: nowrap ;"-->
                    <div style="overflow: hidden; width: 100%">
                        <div
                            v-for="(item, key) in lieBiaoListShiPei"
                            class="contenDiv1"
                            :key="key"
                        >
                            <el-card
                                class="box-card"
                                style="height: 840px; text-align: center"
                            >
                                <div style="width: 100%">
                                    <img
                                        :src="item.thumb_img"
                                        style="width: 100%; height: 300px"
                                    />
                                </div>
                                <p class="pName2">{{ item.name }}</p>
                                <p class="desc1">
                                    {{ item.desc }}
                                </p>
                                <el-button
                                    type="primary"
                                    style="
                    height: 88px;
                    line-height: 0px;
                    font-size: 40px;
                    width: 300px;
                    margin-top: 80px;
                  "
                                    class="btnXiangQ"
                                    @click="chakan(item)"
                                    >查看详情</el-button
                                ><br />
                            </el-card>
                        </div>

                        <div
                            v-if="lieBiaoListShiPei.length === 0"
                            style="
                width: 100px;
                height: 100px;
                margin: 0 auto;
                margin-top: 20px;
              "
                        >
                            暂未上传产品
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="zhanKaiTopShiPei" style="margin-top: 120px">
                    <img
                        src="../assets/wxin.png"
                        style="width: 10%; margin-left: 20px"
                    />
                    <span
                        style="margin-left: 20px; line-height: 40px; font-size: 46px"
                        >联系方式</span
                    >
                </div>
                <div class="biankuangShiPei">
                    <div style="float: left; margin-left: 2%" class="bksp">
                        <p>售前咨询：杨洋</p>
                        <p>联系电话：18665393712</p>
                        <p>邮箱：yangyang@wif.ink</p>
                    </div>
                    <div style="">
                        <img src="../assets/tu.jpg" style="width: 50%" />
                        <p class="pw">长按保存图片，扫码加微信</p>
                    </div>
                </div>
            </div>
        </div>
        <RightLian />

        <div class="page-section2">
            <div>
                <div style="margin-left: 1rem">
                    <div
                        style="
              margin-top: 20px;
              width: 18rem;
              display: inline-block;
              margin-left: 0.5rem;
            "
                        v-for="(item, key2) in chanPingList"
                        :key="key2"
                    >
                        <el-card class="box-card" style="height: 400px">
                            <p class="ptype" v-if="item.type === 1">新品</p>
                            <p class="ptype" v-else>热销</p>
                            <p class="sreen">{{ item.screen_size }}</p>

                            <div style="width: 100%; height: 160px">
                                <img
                                    :src="item.thumb_img"
                                    style="width: 100%; height: 160px; margin-top: 50px"
                                />
                            </div>
                            <div style="margin-top: 100px; text-align: center">
                                <button class="liejiao" @click="chakan(item)">
                                    了解更多
                                </button>

                                <p class="xinghao">
                                    型号：{{ item.product_mode }}
                                </p>
                                <!--  <el-button
                                    type="primary"
                                    class="Pxiangq"
                                    
                                >
                                    查看详情 > >
                                </el-button>-->
                            </div>
                        </el-card>
                    </div>
                </div>
            </div>
            <div class="doc-container" style="margin-left: 1rem">
                <div class="left-contents" style="margin-left: 1%">
                    <div class="zhanKaiTop" @click="chanp">
                        <img
                            src="../assets/chanpfl.png"
                            style="width: 10%; margin-left: 20px"
                        />
                        <span style="margin-left: 20px">产品分类</span>
                    </div>
                    <div style="height: 220px">
                        <el-collapse accordion v-model="a1" class="a1">
                            <div
                                v-if="fenLeiList.length === 0"
                                style="
                  width: 130px;
                  height: 100px;
                  margin: 0 auto;
                  margin-top: 20px;
                "
                            >
                                暂未上传产品分类
                            </div>
                            <div v-for="(item, key) in fenLeiList" :key="key">
                                <el-collapse-item
                                    @click.native="a(item)"
                                    :name="item.id"
                                >
                                    <template slot="title">
                                        <el-button
                                            type="text"
                                            style="margin-left: 10px"
                                        >
                                            {{ item.name }}</el-button
                                        >
                                    </template>

                                    <div
                                        v-if="
                                            item.product_list === '' ||
                                                item.product_list ===
                                                    undefined ||
                                                item.product_list === null ||
                                                item.product_list.length === 0
                                        "
                                    >
                                        <p
                                            style="font-size: 14px; margin-left: 20px"
                                        >
                                            暂未添加产品
                                        </p>
                                    </div>
                                    <div
                                        v-for="(item1,
                                        key1) in item.product_list"
                                        @click="xuanze(item1, item)"
                                        :key="key1"
                                        v-else
                                    >
                                        <el-button
                                            @click="xuanze1"
                                            style="margin-left: 20px"
                                            type="text"
                                            class="text1"
                                            >{{ item1.name }}</el-button
                                        >
                                    </div>
                                </el-collapse-item>
                            </div>
                        </el-collapse>
                    </div>

                    <div class="zhanKaiTop" style="margin-top: 120px">
                        <img
                            src="../assets/wxin.png"
                            style="width: 10%; margin-left: 20px"
                        />
                        <span style="margin-left: 20px">联系方式</span>
                    </div>
                    <div class="biankuang">
                        <p>售前咨询：杨洋</p>
                        <p>联系电话：18665393712</p>
                        <p>邮箱：yangyang@wif.ink</p>
                        <div>
                            <img src="../assets/tu.jpg" />
                        </div>
                    </div>
                    <!-- <div
                        style="margin-top: 20px"
                        v-for="(item, key2) in chanPingList2"
                        :key="key2"
                        v-if="item.type === 2"
                    >
                        <el-card class="box-card" style="height: 300px">
                            <p style="text-align: center; margin-bottom: 20px">
                                {{ item.name }}
                            </p>

                            <div style="width: 32%; float: left; height: 100%">
                                <img
                                    :src="item.thumb_img"
                                    style="margin-top: 50px; width: 100%; height: 100%"
                                />
                            </div>

                            <div
                                style="
                  float: right;
                  display: inline-block;
                  height: 178px;
                  margin-left: -250px;
                  width: 110px;
                  margin-left: -120px;
                "
                            >
                                <div
                                    v-for="item1 in item.config"
                                    style="margin-left: -50px"
                                >
                                    <el-button type="primary" class="btn12">
                                        {{ item1 }}</el-button
                                    ><br />
                                </div>
                                <el-button
                                    type="text"
                                    class="Pxiangq"
                                    @click="chakan(item)"
                                    >查看详情 > ></el-button
                                >
                            </div>
                        </el-card>
                    </div>-->
                </div>
                <!-- <div v-if="isShowResult" class="result">
                    <div @click="toNode(item.id)" class="item-container" v-for="item in results" :key='item.id'>
                        <div class="item-title">{{item.type_name}}</div>
                        <div class="item-content">
                            <div>{{item.type_name}}</div>
                            <div v-html="item.text"></div>
                        </div>
                    </div>
                 </div> -->
                <div class="doc-content md">
                    <div v-if="lieBiaoF">
                        <div style="border: 1px solid #e5e5e5; height: 1120px">
                            <div
                                v-for="(item, key) in lieBiaoList"
                                class="contenDiv"
                                :key="key"
                                style="position: relative"
                            >
                                <el-card
                                    class="box-card"
                                    style="text-align: center"
                                >
                                    <p
                                        class=""
                                        style="color: #409eff; text-align: right"
                                    >
                                        {{ item.screen_size }}
                                    </p>
                                    <div style="width: 100%; height: 130px">
                                        <img
                                            :src="item.thumb_img"
                                            style="width: 100%; height: 140px"
                                        />
                                    </div>
                                    <p class="pName2">
                                        型号：{{ item.product_mode }}
                                    </p>
                                </el-card>
                                <div class="nei">
                                    <div style="height: 85%">
                                        <div class="miaoshu">
                                            <p
                                                class="miaoshu1"
                                                v-for="(item1,
                                                index1) in item.base_config"
                                                :key="index1"
                                            >
                                                {{ item1.name }}
                                                --

                                                {{ item1.value }}
                                            </p>
                                        </div>
                                    </div>
                                    <div style="height: 15%; text-align: right">
                                        <el-button
                                            type="text"
                                            style="
                        height: 28px;
                        line-height: 0px;
                        font-size: 14px;
                        margin-right: 10px;
                        color: #ffffff;
                      "
                                            @click="chakan(item)"
                                            >查看详情 >>></el-button
                                        >
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="lieBiaoList.length === 0"
                                style="
                  width: 100px;
                  height: 100px;
                  margin: 0 auto;
                  margin-top: 20px;
                "
                            >
                                暂未上传产品
                            </div>
                        </div>
                        <div>
                            <div
                                class="page-list"
                                style="text-align: center; margin-top: 20px"
                            >
                                <el-pagination
                                    background
                                    @current-change="pageChange"
                                    :current-page="listQuery.page"
                                    :page-size="10"
                                    layout="total, prev, pager, next, jumper"
                                    :total="total"
                                >
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import "@style/md.css";
// import "@style/styles/monokai-sublime.css"; // 引入高亮样式 这里我用的是sublime样式
import "highlight.js/styles/atom-one-light.css"; // 引入高亮样式 这里我用的是sublime样式

import { Header, Elevate, Footer } from "@components";
import LeftLian from "../components/lianXi/leftLian.vue";
import RightLian from "../components/lianXi/rightLian";

export default {
    name: "App",
    components: {
        Header,
        Elevate,
        Footer,
        LeftLian,
        RightLian
    },
    data() {
        return {
            cpListOne: [],
            cpListTwo: [],

            value: "",
            a1: [],
            List: [1, 2],
            fenLeiList: [],
            chanPingList2: [],
            fenLeiList: [],
            chanPingList: [],
            lieBiaoList: [],
            lieBiaoListShiPei: [],
            lieBiaoF: false,
            chanPingF: false,
            List: [1, 2, 4, 5],
            listQuery: {
                keyword: "",
                page: 1,
                group_id: 0
            },
            listQueryShiPei: {
                keyword: "",
                group_id: 0
            },
            total: 0,

            menus: [
                {
                    text: "首页",
                    id: "app"
                },
                {
                    text: "产品中心",
                    id: "products"
                },

                {
                    text: "联系我们",
                    id: "about"
                },
                {
                    text: "管理平台",
                    id: "guanlipt"
                },
                {
                    text: "开发者文档",
                    id: "devdoc"
                }
            ],
            listData: [
                {
                    title: "API参考",
                    subTitle: "查看API详情",
                    imgSrc: require("../assets/doc/api.png")
                },
                {
                    title: "SDK下载",
                    subTitle: "下载WIF-OS的SDK",
                    imgSrc: require("../assets/doc/sdk.png")
                },
                {
                    title: "FAQ",
                    subTitle: "常见问题回答",
                    imgSrc: require("../assets/doc/question.png")
                }
            ],
            downloadList: [
                {
                    title: "产品文档",
                    subTitle: "查看在线文档",
                    imgSrc: require("../assets/doc/document.png")
                },
                {
                    title: "SDK 下载",
                    subTitle: "物联网管理SDK下载",
                    imgSrc: require("../assets/doc/xiazai.png")
                },
                {
                    title: "WIF-OS下载",
                    subTitle: "网飞安卓系统下载",
                    imgSrc: require("../assets/doc/download.png")
                },
                {
                    title: "社区中心",
                    subTitle: "前往开发者社区",
                    imgSrc: require("../assets/doc/shequ.png")
                }
            ],
            bannerList: []
        };
    },
    mounted() {
        let appInfo = this.$route.query.appInfo;
        /*   console.log(
            appInfo === "" || appInfo === undefined || appInfo === null
        );*/
        if (appInfo === "" || appInfo === undefined || appInfo === null) {
            this.listQuery.group_id = 0;
            this.listQueryShiPei.group_id = 0;
        } else {
            let appInfo = JSON.parse(this.$route.query.appInfo);
            this.listQuery.group_id = appInfo.id;
            this.listQueryShiPei.group_id = appInfo.id;
            let a = [];
            a.push(this.listQuery.group_id);
            this.a1 = a;
            //  // console.log(a);
        }
        this.getHot();
        this.getList();
        this.getGroup();
        this.getListShiPei();
        this.getAdList();
    },
    methods: {
        chanp() {
            /* console.log(this.fenLeiList);
      if (this.a1.length === 0) {
        for (let i = 0; i < this.fenLeiList.length; i++) {
          this.a1.push(this.fenLeiList[i].id);
        }
      } else {
        
      }*/
            this.listQuery.keyword = "";
            this.listQuery.page = 1;
            this.listQuery.group_id = 0;

            this.getList();
            this.a1 = [];
        },
        changeXuze(item) {
            // console.log(item);
            this.listQueryShiPei.group_id = item;
            this.getListShiPei();
        },
        a(item, item1) {
            this.listQuery.group_id = item.id;
            this.getList();
        },
        xuanze1(item) {
            // // console.log(item);
        },

        chakan(scope) {
            //  // console.log(scope);
            let param = {
                id1: scope.id,
                id: scope.product_id
            };

            this.$router.push({
                path: "/proDetail",
                query: { appInfo: JSON.stringify(param) }
            });
        },
        xuanze(item, item1) {
            let param = {
                id1: item1.id,
                id: item.product_id,
                name: item1.name
            };
            this.$router.push({
                path: "/proDetail",

                query: { appInfo: JSON.stringify(param) }
            });
        },
        getGroup() {
            this.axios({
                url: "/index/product/getGroup",
                method: "post",
                data: {}
            }).then(res => {
                this.fenLeiList = res.data.data;
                //// console.log(res);
            });
        },
        getHot() {
            this.axios({
                url: "/index/product/getHot",
                method: "post",
                data: {}
            }).then(res => {
                this.chanPingF = true;
                this.chanPingList2 = res.data.data;

                this.chanPingList = res.data.data;
                //       console.log(res);
                let a = res.data.data;
                let b = res.data.data;
                // console.log(b);
                this.cpListOne = a.slice(0, 2);
                this.cpListTwo = b.slice(-2);
                // console.log(this.cpListOne);
                // console.log(this.cpListTwo);
            });
        },

        chakan(scope) {
            let param = {
                id: scope.id
            };

            this.$router.push({
                path: "/proDetail",
                query: { appInfo: JSON.stringify(param) }
            });
        },

        pageChange(page) {
            // //  // console.log(page)
            this.listQuery.page = page;
            this.getList();
        },

        getAdList() {
            this.axios({
                url: "/index/ad/getList",
                method: "post",
                data: {
                    position_id: "2"
                }
            }).then(res => {
                // console.log(res.data.data);
                this.bannerList = res.data.data;
            });
        },
        getList() {
            this.axios({
                url: "/index/product/getList",
                method: "post",
                data: {
                    keyword: this.listQuery.keyword,
                    page: this.listQuery.page,
                    group_id: this.listQuery.group_id
                }
            }).then(res => {
                this.lieBiaoF = true;
                this.lieBiaoList = res.data.data.data;
                this.total = res.data.data.total;
            });
        },

        getListShiPei() {
            this.axios({
                url: "/index/product/getList",
                method: "post",
                data: {
                    keyword: this.listQueryShiPei.keyword,
                    page: 0,
                    group_id: this.listQueryShiPei.group_id
                }
            }).then(res => {
                this.lieBiaoListShiPei = res.data.data;
                //  console.log(res.data.data);
            });
        }
    }
};
</script>
<style scoped>
::v-deep .el-carousel__container {
    height: 373px;
}
.banner {
    margin-top: 60px;
    height: 373px;
    width: 100%;
    background-size: cover;
}
/deep/ .el-collapse-item__header.is-active {
    background-color: cornflowerblue;
}
/deep/ .el-collapse-item__header.is-active > .el-button {
    color: #fff;
}
.miaoshu1 {
    color: #fff;
    font-size: 16px;
}
.miaoshu {
    padding-top: 60px;
}
.sreen {
    float: right;
    display: inline-block;
    color: #666666;
    padding-top: 10px;
    font-weight: bolder;
    font-size: 20px;
}
.ptype {
    display: inline-block;
    text-shadow: 0 0 3px red, 0 0 5px red;
    color: #ffffff;
    font-weight: bolder;
}
.liejiao {
    width: 90px;
    height: 30px;
    border: 0px;
    border-radius: 25px;
    font-size: 14px;
    background-color: rgb(17, 163, 248);
    color: #ffffff;
}
.xinghao {
    margin-top: 10px;
    font-size: 12px;
    color: #666666;
}
</style>

<style scoped="scoped">
.nei {
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
    height: 102%;
    background: rgb(95, 122, 167);
    opacity: 0.8;
    text-align: center;
    transform: top 1s ease-out;
}
.contenDiv:hover .nei {
    display: inline-block;
}

/deep/ .el-select {
    font-size: 200px;
}
.page-section1 {
    padding-top: 60px;
    overflow-y: scroll;
    min-height: 2000px;
    width: 1350px;
    margin-bottom: 50px;
    margin: 0 auto;
    margin-left: 1%;
}

.page-section2 {
    overflow-y: scroll;
    min-height: 2000px;
    margin: 0 auto;
    width: 1200px;
    margin-bottom: 50px;
}
.doc-container {
    margin-left: 3rem;
    background: #fff;
    border-radius: 5px;

    margin-top: 30px;
    position: relative;
}
.left-contents {
    /* height:calc(100% - 88px); */
    height: 100%;
    overflow-y: scroll;
    width: 270px;
    float: left;
    /* border-right: 1px solid rgb(235,236,237); */
}
.left-contents /deep/ .el-input {
    width: 90%;
    margin: 5%;
}
.doc-content {
    width: calc(100% - 300px);
    height: 100%;
    overflow-y: scroll;
    float: right;
}
.el-tree {
    color: #000000;
    font-size: 16px;
}
.el-tree /deep/ .is-current {
    /* color: #0066ff; */
    background-color: rgba(0, 102, 255, 0.1);
}
.el-tree /deep/ .el-tree-node:focus > .el-tree-node__content {
    /* color: #0066ff; */
    background-color: rgba(0, 102, 255, 0.1);
}
.el-tree /deep/ .el-tree-node__content:hover {
    background-color: rgba(0, 102, 255, 0.1);
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: none;
    /* background-color: #F5F5F5; */
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #ffffff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    /* background-color: #c8c8c8; */
    background-color: rgba(0, 102, 255, 0.4);
}

.item-container {
    width: 500px;
}
.item-title {
    font-size: 20px;
    font-weight: 600;
    background-color: rgb(228, 233, 238);
    border-radius: 2px;
    line-height: 40px;
    padding: 0 5px;
}

.item-content > div:first-child {
    background-color: rgb(248, 250, 252);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px;
    width: 150px;
    border-right: 1px solid rgb(237, 239, 242);
    width: 150px;
}
.item-content > div:last-child {
    padding: 10px;
    width: 350px;
}
</style>

<style scoped>
/deep/ .el-carousel__indicators--outside button {
    background-color: rgb(42, 130, 228);
}
/deep/ .el-carousel__button {
    width: 100px;
}
/*移动端适配 */
.chanPingShiPeiList {
    margin-top: 20px;
    width: 40.5rem;
    display: inline-block;
    margin-left: 10px;
}
.btn2 {
    min-width: 185px;
    font-size: 2px;
    transform: scale(2);
    height: 40px;
    font-size: 13px;
    display: inline-block;
    margin-top: 66px;
    margin-left: 28%;
}
.zhanKaiTopShiPei {
    width: 98%;
    color: #fff;
    margin-left: 10px;
    line-height: 56px;
    background-color: rgb(42, 130, 228);
}

.biankuangShiPei {
    width: 98%;
    margin-left: 10px;
    text-align: left;
    font-size: 14px;
    border: 1px solid rgb(42, 130, 228);
    margin-bottom: 20px;
    height: 740px;
}
.pw {
    color: rgb(255, 141, 26);
    font-size: 36px;
    margin-left: 51.5%;
}
.bksp > p {
    color: rgb(255, 141, 26);
    margin-top: 20px;
    margin-left: 10px;
    font-size: 52px;
    line-height: 166px;
}
.contenDiv1 {
    margin-top: 10px;
    height: 35%;
    width: 40.5rem;
    margin-left: 10px;

    display: inline-block;
}

/** */
.contenDiv {
    margin-top: 10px;
    height: 25%;
    width: calc(100% - 620px);

    margin-left: 20px;
    display: inline-block;
}

.btn12 {
    min-width: 200px;
    font-size: 2px;
    transform: scale(0.75);
    font-size: 14px;
    margin-top: 10px;
}
/deep/ .el-collapse-item__arrow {
    margin: 0 28px 0 auto;
}
.text1 {
    text-decoration: underline;
    color: rgb(255, 141, 26);
}
.Pxiangq {
    color: red;
    margin-left: 10px;
}
/deep/ .el-submenu__icon-arrow {
    color: rgb(42, 130, 228);
}
/deep/ .el-icon-arrow-down {
    color: rgb(42, 130, 228);
}

/deep/ .el-collapse-item {
    border-bottom: 1px solid #e5e5e5;
}

/deep/ .el-collapse {
    width: 265px;
    height: 290px;
    border: 1px solid rgb(42, 130, 228);
    overflow-x: hidden;
}

.neiRong {
    width: 60%;
    height: 200px;
}
.zhanKaiTop {
    color: #fff;
    line-height: 56px;
    height: 56px;
    width: 265px;
    background-color: rgb(42, 130, 228);
}

.xianshi {
    border: 1px solid rgb(42, 130, 228);
    width: 265px;
}

.biankuang {
    text-align: left;
    font-size: 14px;
    border: 1px solid rgb(42, 130, 228);
    width: 265px;
}
.biankuang > p {
    color: rgb(42, 130, 228);
    margin-left: 16%;
    margin-top: 10px;
}
/deep/ .el-button--primary {
    border: 0px solid white;
}
.pName2 {
    color: #409eff;
    margin-top: 40px;
    font-weight: bolder;
    font-size: 16px;
}
.desc1 {
    text-align: center;
    height: 40px;
    font-size: 14px;
    margin-top: 10px;
}
.chanping {
    text-align: center;
}
.btn1 {
    min-width: 185px;
    font-size: 2px;
    transform: scale(0.8);
    height: 40px;
    font-size: 13px;
    display: inline-block;
    margin: 0 auto;
    margin-top: 10px;
    margin-left: 12%;
}

.xuanzhuan {
    float: right;
    background-color: red;
    font-weight: bolder;
    transform: rotate(25deg);
}
.Pxiangq {
    color: red;
    margin-left: 10px;
    margin-top: 10px;
}
.pName {
    float: left;
    margin-right: 20%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bolder;
}

.text {
    font-size: 14px;
}

.item {
    padding: 18px 0;
}
</style>

<style scoped>
@media screen and (max-width: 1400px) {
    ::v-deep .el-carousel__container {
        height: 292px;
    }
    .banner {
        height: 292px;
    }
}
@media screen and (max-width: 1200px) {
    ::v-deep .el-carousel__container {
        height: 262px;
    }
    .banner {
        height: 262px;
    }
}
@media screen and (min-width: 1100px) {
    /* .daohang{
    display: none;
  }*/
    .page-section1 {
        display: none;
    }
}
@media screen and (max-width: 1100px) {
    ::v-deep .el-input input::-webkit-input-placeholder {
        color: #000000;
    }
    ::v-deep .el-input input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #000000;
    }
    ::v-deep .el-input input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #000000;
    }
    ::v-deep .el-input input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000000;
    }
    ::v-deep .el-input {
        color: #000000;
        border: 1px solid #000000;
    }
    ::v-deep .el-input__inner {
        height: 80px;
        font-size: 24px;
        color: #000000;
    }
    .page-section2 {
        display: none;
    }

    ::v-deep .el-select-dropdown__item {
        font-size: 69px;
    }
    ::v-deep .shuru .el-input {
        width: 400px;
    }

    .btnXiangQ {
        font-size: 26px;
    }
    .pName2 {
        margin-top: 80px;
        font-size: 46px;
    }
    .desc1 {
        font-size: 36px;
        margin-top: 60px;
    }
}
</style>
