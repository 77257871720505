<template>
    <ul class="elevate">
        <li v-for="(item,index) in list"
            :key="index"
            class="elevate-item">
            <div v-if="item.imgSrc"
                 class="elevate-item-left">
                <img :src="item.imgSrc"
                     alt="">
            </div>
            <div class="elevate-item-right">
                <p class="elevate-item-num">
                    {{ item.title }}
                </p>
                <p class="elevate-item-text">
                    {{ item.subTitle }}
                </p>
            </div>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'Evevate',
    props: {
        list: {
            type: Array
        }
    },
    data() {
        return {

        }
    }
}
</script>
