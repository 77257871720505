<template>
    <div class="layout">
        <Header
            :menus="menus"
            :title="'文档中心'"
            :subTitle="'行业主板'"
        ></Header>

        <div class="page-section">
            <div class="container container-menu">
                <div class="container-wrap menu-wrap">
                    <ul class="menu-content">
                        <li class="active">
                            文档
                        </li>
                        <li>API参考</li>
                        <li>SDK下载</li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
            <div class="container container-detail">
                <div class="container-wrap detail-wrap">
                    <ul class="detail-menu">
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/doc/hangye.png" alt="" />
                                <span>行业主板</span>
                            </div>
                            <ul class="detail-menu-sub">
                                <li>
                                    A33-1-A型
                                </li>
                                <li>
                                    R88-1-A型
                                </li>
                                <li>
                                    R88-1-B型
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/doc/hangye.png" alt="" />
                                <span>行业主板</span>
                            </div>
                            <ul class="detail-menu-sub">
                                <li>
                                    A33-1-A型
                                </li>
                                <li>
                                    R88-1-A型
                                </li>
                                <li>
                                    R88-1-B型
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/doc/monitor.png" alt="" />
                                <span>安卓一体机</span>
                            </div>
                            <ul class="detail-menu-sub">
                                <li>
                                    A01107型
                                </li>
                                <li>
                                    A01107型
                                </li>
                                <li>
                                    A01107型
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/doc/os.png" alt="" />
                                <span>WIF-OS</span>
                            </div>

                            <ul class="detail-menu-sub">
                                <li>
                                    Android 1.2.1
                                </li>
                                <li>
                                    Android 1.1.1
                                </li>
                                <li>
                                    Android 1.0.1
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="detail-content">
                        <div class="detail-content-top">
                            <div class="detail-content-top-img">
                                <img src="../assets/product.png" />
                            </div>
                            <div class="detail-content-top-text">
                                <div class="detail-content-top-title">
                                    A33-1-A型
                                </div>
                                <div class="detail-content-top-subTitle">
                                    1G基础版控制型主板
                                    A33-1-A型号主板主控是A33型，1G内存，2G硬盘。适合基础的控制性系统使用。适合于产品的开发和
                                    批量处理。
                                </div>
                                <div class="detail-content-top-btn">
                                    立即购买
                                </div>
                            </div>
                        </div>
                        <ul class="detail-content-doc">
                            <li class="detail-content-doc-item">
                                <div class="detail-content-doc-item-title">
                                    <img src="../assets/doc/tool.png" alt="" />
                                    <span>工具</span>
                                </div>
                                <ul class="detail-content-doc-item-list">
                                    <li>RK驱动助手</li>
                                    <li>Linux-upgrade-tool</li>
                                </ul>
                            </li>
                            <li class="detail-content-doc-item">
                                <div class="detail-content-doc-item-title">
                                    <img
                                        src="../assets/doc/gujian.png"
                                        alt=""
                                    />
                                    <span>主板固件</span>
                                </div>
                                <ul class="detail-content-doc-item-list">
                                    <li>A33-1-A-GJ</li>
                                </ul>
                            </li>
                            <li class="detail-content-doc-item">
                                <div class="detail-content-doc-item-title">
                                    <img src="../assets/doc/sdk-1.png" alt="" />
                                    <span>系统SDK</span>
                                </div>
                                <ul class="detail-content-doc-item-list">
                                    <li>APN参数</li>
                                    <li>移动4G模块调试</li>
                                    <li>RS485串口调试</li>
                                </ul>
                            </li>
                            <li class="detail-content-doc-item">
                                <div class="detail-content-doc-item-title">
                                    <img src="../assets/doc/doc.png" alt="" />
                                    <span>文档</span>
                                </div>
                                <ul class="detail-content-doc-item-list">
                                    <li>API文档</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container container-experience">
                <div class="container-wrap">
                    <div class="experience-title">
                        免费试用名额等你来拿，赶紧注册吧！
                    </div>
                    <ul class="experience-list">
                        <li>
                            <div class="experience-dot"></div>
                            行业主板
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            安卓智慧屏
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            WIF-OS
                        </li>
                    </ul>
                    <div class="experience-btn">
                        立即体验
                    </div>
                </div>
            </div>
        </div>

        <div class="container container-footer">
            <div class="footer-wrap">
                <span> 2021 Agora, Inc. All rights reserved. </span>
                <span>wif.ink</span>
                <img src="../assets/icon/about.png" alt="" />
                <span>关于我们</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Header } from "@components";

export default {
    name: "App",
    components: {
        Header
    },
    data() {
        return {
            menus: [
                {
                    text: "Wif.ink",
                    id: "app"
                },
                {
                    text: "社区",
                    id: "about"
                }
            ]
        };
    }
};
</script>
