<template>
    <div class="layout">
        <Header :menus="menus"></Header>
        <div class="container container-menu">
            <div class="container-wrap menu-wrap">
                <ul class="menu-content">
                    <li class="active">
                        文档
                    </li>

                    <li>FAQ</li>
                </ul>
            </div>
        </div>
        <div class="container container-detail">
            <div class="container-wrap detail-wrap">
                <ul class="detail-menu">
                    <li>
                        <div class="detail-menu-main">
                            <img src="../assets/icon/assets-1.png" alt="" />
                            <span>前言</span>
                        </div>
                    </li>
                    <li>
                        <div class="detail-menu-main">
                            <img src="../assets/icon/assets-2.png" alt="" />
                            <span>快速入门</span>
                        </div>
                        <ul class="detail-menu-sub">
                            <li>
                                架构简介
                            </li>
                            <li>
                                固件版本说明
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div class="detail-menu-main">
                            <img src="../assets/icon/assets-3.png" alt="" />
                            <span>接口文档</span>
                        </div>
                    </li>
                    <li>
                        <div class="detail-menu-main">
                            <img src="../assets/icon/assets-4.png" alt="" />
                            <span>版本查询</span>
                        </div>

                        <ul class="detail-menu-sub">
                            <li>
                                V1.5.0版本文档
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="detail-content">
                    <div class="detail-content-bread">
                        <span
                            ><a href="">文档中心</a>> <a href="">快速入门 </a> >
                            <a href=""> 架构简介 </a></span
                        >
                    </div>
                    <div class="detail-content-text">
                        <div class="detail-content-text-left">
                            <p>
                                <strong>1对N在线小班课</strong>
                            </p>

                            <p>
                                更新时间 2021/04/26 16:24:12
                            </p>

                            <p>
                                <b style="font-size:22px;">场景描述</b>
                            </p>

                            <p>
                                三包是零售商业企业对所售商品实行”包修包换、包退”的简称。指商品进入消费领域后，卖方对买方所购物品负责而
                                采取的在一定限期内的一种信用保证办法。对不是因用户使用、保管不当，而属于产品质量问题而发生的故障提供该项服务。
                            </p>

                            <p><b>1三包产品</b></p>

                            <p>
                                第一批实施三包的部分产品共18种:自行车、彩电、黑白电视、家用录像机、摄像机、收录机、电子琴、家用电冰箱、
                                洗衣机、电风扇、微波炉、吸尘器、家用空调器、吸排油烟机、燃气热水器、缝纫机、钟表、摩托车。
                            </p>

                            <p>
                                新三包规定中明确，实行三包的产品目录将由国务院有关部门定和调整。
                            </p>

                            <p>
                                随着移动电话、固定电话、微型计算机、家用视听产品等四类新产品的加入，目前我国共有22种产品
                                纳入“三包"政策范围之内。
                            </p>

                            <p>进口产品同样适用于新三包规定。</p>
                        </div>
                        <div class="detail-content-text-right">
                            <ul>
                                <li class="active">
                                    场景描述
                                </li>
                                <li>功能列表</li>
                                <li>技术方案</li>
                                <li>方案优势</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container container-experience">
                <div class="experience-wrap">
                    <div class="experience-title">
                        免费试用名额等你来拿，赶紧注册吧！
                    </div>
                    <ul class="experience-list">
                        <li>
                            <div class="experience-dot"></div>
                            行业主板
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            安卓智慧屏
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            WIF-OS
                        </li>
                    </ul>
                    <div class="experience-btn">
                        立即体验
                    </div>
                </div>
            </div>
        </div>
        <DocFooter />
    </div>
</template>

<script>
import { Header, DocFooter } from "@components";

export default {
    name: "App",
    components: {
        Header,
        DocFooter
    },
    data() {
        return {
            menus: [
                {
                    text: "Wif.ink",
                    id: "app"
                },
                {
                    text: "开发者社区",
                    id: "develop"
                }
            ],
            listData: [
                {
                    title: "开发文档",
                    subTitle: "查看开发文档详情",
                    imgSrc: require("../assets/doc/develop.png")
                },
                {
                    title: "FAQ",
                    subTitle: "常见问题回答",
                    imgSrc: require("../assets/doc/question.png")
                }
            ],
            downloadList: [
                {
                    title: "产品文档",
                    subTitle: "查看在线文档",
                    imgSrc: require("../assets/doc/document.png")
                },
                {
                    title: "SDK 下载",
                    subTitle: "物联网管理SDK下载",
                    imgSrc: require("../assets/doc/xiazai.png")
                },
                {
                    title: "WIF-OS下载",
                    subTitle: "网飞安卓系统下载",
                    imgSrc: require("../assets/doc/download.png")
                },
                {
                    title: "社区中心",
                    subTitle: "前往开发者社区",
                    imgSrc: require("../assets/doc/shequ.png")
                }
            ]
        };
    }
};
</script>
